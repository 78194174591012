import React from "react";

import async from "../components/Async";

import {
  BookOpen,
  Briefcase,
  Calendar as CalendarIcon,
  CheckSquare,
  Map,
  Sliders,
  Users,
  User,
  Bell,
  Archive,
  Book,
  Truck,
  MessageSquare,
  MessageCircle,
  Codesandbox,
  TrendingUp,
  Send
} from "react-feather";


// View ALERTS
const Analytics_Alert = async(() => import("../pages/dashboards/Analytics_Alert"));
const Admins_Alert = async(() => import("../pages/pages/Admins_Alert"));
const Admin_Alert = async(() => import("../pages/pages/Admin_Alert"));
const Users_Alert = async(() => import("../pages/pages/Users_Alert"));
const User_Alert = async(() => import("../pages/pages/User_Alert"));
const States_Alert = async(() => import("../pages/pages/States_Alert"));
const State_Alert = async(() => import("../pages/pages/State_Alert"));
const Coupons_Alert = async(() => import("../pages/pages/Coupons_Alert"));
const Coupon_Alert = async(() => import("../pages/pages/Coupon_Alert"));
const Blogs_Alert = async(() => import("../pages/pages/Blogs_Alert"));
const Blog_Alert = async(() => import("../pages/pages/Blog_Alert"));
const Vehicles_Alert = async(() => import("../pages/pages/Vehicles_Alert"));
const Vehicle_Alert = async(() => import("../pages/pages/Vehicle_Alert"));
const Doubles_Alert = async(() => import("../pages/pages/Doubles_Alert"));
const Double_Alert = async(() => import("../pages/pages/Double_Alert"));
const Notifications_Alert = async(() => import("../pages/pages/Notifications_Alert"));
const Notification_Alert = async(() => import("../pages/pages/Notification_Alert"));
const Events_Alert = async(() => import("../pages/pages/Events_Alert"));
const Event_Alert = async(() => import("../pages/pages/Event_Alert"));
const Payments_Alert = async(() => import("../pages/pages/Payments_Alert"));
const Results_Alert = async(() => import("../pages/pages/Results_Alert"));
const VehiclesFilter_Alert = async(() => import("../pages/pages/VehiclesFilter_Alert"));

// Auth components ALERTS
const SignIn_Alert = async(() => import("../pages/auth/SignIn_Alert"));

// Guards
const AuthGuard_Alert = async(() => import("../components/AuthGuard_Alert"));

// Auth components
const Page404 = async(() => import("../pages/auth/Page404"));
const Page500 = async(() => import("../pages/auth/Page500"));

// Contingencia Ambienta UI
const ContingenciaPage =  async(() => import("../pages/contingencia"));

const contingencia_Alert = {
  id: "Contingencia",
  path: "/contingencia",
  icon: <Send />,
  component: ContingenciaPage,
  children: null,
  guard: AuthGuard_Alert
};


const dashboardsRoutes_Alert = {
  id: "Dashboard",
  path: "/",
  icon: <Sliders />,
  component: Analytics_Alert,
  children: null,
  guard: AuthGuard_Alert
};

const admins_Alert = {
  id: "Administradores",
  path: "/admins",
  icon: <Users />,
  component: Admins_Alert,
  children: null,
  guard: AuthGuard_Alert
};

const admin_Alert = {
  id: "Administrador",
  path: "/admin",
  icon: <Users />,
  component: Admin_Alert,
  children: null,
  guard: AuthGuard_Alert
};

const admin_id_Alert = {
  id: "Administrador",
  path: "/admin/:id",
  icon: <Users />,
  component: Admin_Alert,
  children: null,
  guard: AuthGuard_Alert
};

const user_Alert = {
  id: "Usuario",
  path: "/user/:id",
  icon: <User />,
  component: User_Alert,
  children: null,
  guard: AuthGuard_Alert
};

const users_Alert = {
  id: "Usuarios",
  path: "/users",
  icon: <User />,
  component: Users_Alert,
  children: null,
  guard: AuthGuard_Alert
};

const coupons_Alert = {
  id: "Cupones",
  path: "/coupons",
  icon: <Archive />,
  component: Coupons_Alert,
  children: null,
  guard: AuthGuard_Alert
};

const coupon_Alert = {
  id: "Cupon",
  path: "/coupon",
  icon: <Archive />,
  component: Coupon_Alert,
  children: null,
  guard: AuthGuard_Alert
};

const blogs_Alert = {
  id: "Blog",
  path: "/blogs",
  icon: <Book />,
  component: Blogs_Alert,
  children: null,
  guard: AuthGuard_Alert
};

const blog_Alert = {
  id: "Blog",
  path: "/blog",
  icon: <Book />,
  component: Blog_Alert,
  children: null,
  guard: AuthGuard_Alert
};

const blog_id_Alert = {
  id: "Blog",
  path: "/blog/:id",
  icon: <Book />,
  component: Blog_Alert,
  children: null,
  guard: AuthGuard_Alert
};

const vehiclesFilter_Alert = {
  id: "Vehículos",
  path: "/vehiclesfilter",
  icon: <Truck />,
  component: VehiclesFilter_Alert,
  children: null,
  guard: AuthGuard_Alert
};

const vehicles_Alert = {
  id: "Vehículos",
  path: "/vehicles",
  icon: <Truck />,
  component: Vehicles_Alert,
  children: null,
  guard: AuthGuard_Alert
};

const vehicle_Alert = {
  id: "Vehículo",
  path: "/vehicle/:id",
  icon: <Truck />,
  component: Vehicle_Alert,
  children: null,
  guard: AuthGuard_Alert
};

const payments_Alert = {
  id: "Pagos",
  path: "/payments",
  icon: <TrendingUp />,
  component: Payments_Alert,
  children: null,
  guard: AuthGuard_Alert
};


const results_Alert = {
  id: "Resultados",
  path: "/results",
  icon: <TrendingUp />,
  component: Results_Alert,
  children: null,
  guard: AuthGuard_Alert
};


const double_Alert = {
  id: "Doble",
  path: "/double",
  icon: <MessageCircle />,
  component: Double_Alert,
  children: null,
  guard: AuthGuard_Alert
};

const doubles_Alert = {
  id: "Doble no circula",
  path: "/doubles",
  icon: <MessageCircle />,
  component: Doubles_Alert,
  children: null,
  guard: AuthGuard_Alert
};

const notification_Alert = {
  id: "Doble",
  path: "/notification",
  icon: <MessageSquare />,
  component: Notification_Alert,
  children: null,
  guard: AuthGuard_Alert
};

const notifications_Alert = {
  id: "Notificaciones",
  path: "/notifications",
  icon: <MessageSquare />,
  component: Notifications_Alert,
  children: null,
  guard: AuthGuard_Alert
};

const event_id_Alert = {
  id: "Event",
  path: "/event/:id",
  icon: <Codesandbox />,
  component: Event_Alert,
  children: null,
  guard: AuthGuard_Alert
};

const event_Alert = {
  id: "Event",
  path: "/event",
  icon: <Codesandbox />,
  component: Event_Alert,
  children: null,
  guard: AuthGuard_Alert
};

const events_Alert = {
  id: "Eventos",
  path: "/events",
  icon: <Codesandbox />,
  component: Events_Alert,
  children: null,
  guard: AuthGuard_Alert
};

const alerts_Alert = {
  id: "Alertas",
  path: "/alerts",
  icon: <Bell />,
  component: Vehicle_Alert,
  children: null,
  guard: AuthGuard_Alert
};

const state_Alert = {
  id: "Estado",
  path: "/state/:id",
  icon: <Map />,
  component: State_Alert,
  children: null,
  guard: AuthGuard_Alert
};

const states_Alert = {
  id: "Estados",
  path: "/states",
  icon: <Map />,
  component: States_Alert,
  children: null,
  guard: AuthGuard_Alert
};

const contents_Alert = {
  id: "Contenido",
  path: "/contents",
  icon: <CheckSquare />,
  component: Vehicle_Alert,
  children: null,
  guard: AuthGuard_Alert
};

const marketing_Alert = {
  id: "Marketing",
  path: "/marketing",
  icon: <CalendarIcon />,
  component: Vehicle_Alert,
  children: null,
  guard: AuthGuard_Alert
};


const authRoutes_Alert = {
  id: "Auth",
  path: "/auth",
  icon: <Users />,
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn_Alert,
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500,
    },
  ],
  component: null,
};

// Routes using the Auth layout Alert
export const authLayoutRoutes_Alert = [authRoutes_Alert];


export const dashboardLayoutRoutes_Alert = (access) => {

  var routes = [];

  routes.push(dashboardsRoutes_Alert);
  routes.push(vehiclesFilter_Alert);
  if (access) {


    if (access.find(item => item == "admin")) {
      routes.push(admin_Alert);
      routes.push(admin_id_Alert);
      routes.push(admins_Alert);
    }

    if (access.find(item => item == "user")) {
      routes.push(user_Alert);
      routes.push(users_Alert);
    }

    if (access.find(item => item == "state")) {
      routes.push(state_Alert);
      routes.push(states_Alert);
    }

    if (access.find(item => item == "coupon")) {
      routes.push(coupon_Alert);
      routes.push(coupons_Alert);
    }

    if (access.find(item => item == "vehicle")) {
      routes.push(vehicle_Alert);
      routes.push(vehicles_Alert);
    }

    if (access.find(item => item == "blog")) {
      routes.push(blog_Alert);
      routes.push(blogs_Alert);
      routes.push(blog_id_Alert);
    }

    if (access.find(item => item == "double")) {
      routes.push(double_Alert);
      routes.push(doubles_Alert);
    }

    if (access.find(item => item == "notification")) {
      routes.push(notification_Alert);
      routes.push(notifications_Alert);
      routes.push(contingencia_Alert);
    }

    if (access.find(item => item == "event")) {
      routes.push(event_Alert);
      routes.push(events_Alert);
      routes.push(event_id_Alert);
    }

    if (access.find(item => item == "payment")) {
      routes.push(payments_Alert);

    }

    routes.push(results_Alert)

    return routes;
  }

  return routes;

}

// Routes using the Dashboard layout
/*export const dashboardLayoutRoutes_Alert = [
  dashboardsRoutes_Alert,

  admins_Alert,

  users_Alert,
  user_Alert,

  state_Alert,
  states_Alert,

  coupons_Alert,
  coupon_Alert,

  vehicles_Alert,
  vehicle_Alert,

  blogs_Alert,
  blog_Alert,

  double_Alert,
  doubles_Alert,

  notification_Alert,
  notifications_Alert,

  event_Alert,
  events_Alert,
  event_id_Alert,

  contents_Alert,
  marketing_Alert,
  admin_Alert,  
  alerts_Alert,
  payments_Alert,
];*/

export const sidebarRoutes_Alert = (access) => {
  var routes = [];

  routes.push(dashboardsRoutes_Alert);

  if (access) {


    if (access.find(item => item == "admin")) {
      routes.push(admins_Alert);
    }

    if (access.find(item => item == "user")) {
      routes.push(users_Alert);
    }

    if (access.find(item => item == "state")) {
      routes.push(states_Alert);
    }

    if (access.find(item => item == "coupon")) {
      routes.push(coupons_Alert);
    }

    if (access.find(item => item == "vehicle")) {
      routes.push(vehicles_Alert);
    }

    if (access.find(item => item == "payment")) {
      routes.push(payments_Alert);
    }

    if (access.find(item => item == "blog")) {
      routes.push(blogs_Alert);
    }

    if (access.find(item => item == "double")) {
      routes.push(doubles_Alert);
    }

    if (access.find(item => item == "notification")) {
      //routes.push(notifications_Alert);
      // Aqui se agrega la contingencia al menu
      routes.push(contingencia_Alert);
    }

    if (access.find(item => item == "event")) {
      routes.push(events_Alert);
    }

    return routes;
  }
  return routes;
};

/*export const sidebarRoutes_Alert = [
  dashboardsRoutes_Alert,

  admins_Alert,

  users_Alert,

  states_Alert,

  coupons_Alert,

  vehicles_Alert,

  blogs_Alert,  

  doubles_Alert,

  notifications_Alert,

  events_Alert
];*/
