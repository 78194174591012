import * as types from "../../constants";

const initialState = {
    loading: false,
    general: {},
    resumeNotDrive: null,
    resumeVerificacion: null
}

export default function reducer(state = initialState, actions) {
    switch (actions.type) {

        case types.SEARCH_GET_REQUEST:
            return {
                ...state,
                loading: true,
                general: {}
            };

        case types.DASHBOARD_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                general: actions.results,
                resumeNotDrive: actions.results.resumeNotDrive,
                resumeVerificacion: actions.results.resumeVerificacion
            };

        case types.DASHBOARD_NOTDRIVE_GET_SUCCESS:
            return {
                ...state,
                resumeNotDrive: actions.results.resumeNotDrive,
            };

        case types.DASHBOARD_VERIFICAION_GET_SUCCESS:
            return {
                ...state,
                resumeVerificacion: actions.results.resumeVerificacion,
            };

        default:
            return state;
    }
}