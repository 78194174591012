import React from "react";
import styled, { createGlobalStyle } from "styled-components/macro";
import { CssBaseline, Grid, Paper } from "@material-ui/core";

import { Images } from "../utils";

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }
  body {
    background: ${(props) => props.theme.palette.background.default};
  }
`;

const renderImage = () => {
  var num = Math.floor(Math.random() * 3);

  switch (num) {
    case 0:
      return Images.SIGNIN_SCREEN_1;

    case 1:
      return Images.SIGNIN_SCREEN_2;

    case 2:
      return Images.SIGNIN_SCREEN_3;

    default:
      return Images.SIGNIN_SCREEN_1;
  }
}

const Root = styled.div`
  box-sizing: border-box;
  display: flex;    
  flex-wrap: wrap;
  width: 100%;    
  flex-direction: row;
  height: 100vh;
`;

const GridImage = styled.div`

    @media (min-width: 600px){
      -webkit-flex-basis: 50%;
      -ms-flex-preferred-size: 50%;
      flex-basis: 50%;
      -webkit-box-flex: 0;
      -webkit-flex-grow: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      max-width: 50%;
    }

    @media (min-width: 960px) {
      -webkit-flex-basis: 58.333333%;
      -ms-flex-preferred-size: 58.333333%;
      flex-basis: 58.333333%;
      -webkit-box-flex: 0;
      -webkit-flex-grow: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      max-width: 58.333333%;
    }

    box-sizing: border-box;
    margin: 0;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    background-image: url(${renderImage()});
    background-repeat: no-repeat;
    background-color: #fafafa;
    -webkit-background-size: cover;
    background-size: cover;
    -webkit-background-position: center;
    background-position: center;
`;


const Auth = ({ children }) => {
  return (
    <Grid container component="main" sx={{ height: '100vh' }} style={{ height: '100vh' }}>
      <CssBaseline />

      <Grid
        item
        xs={12}
        sm={6}
        md={5}
        component={Paper}
        elevation={6}
        square
        style={{ backgroundColor: "#3A3A5A", display: "flex", justifyContent: "center", alignItems: "center" }}>
        {children}
      </Grid>

      <GridImage
        item
        xs={false}
      />

    </Grid >
  );
};

export default Auth;
