import * as types from "../../constants";

const initialState = {
  filtersCoupons: [],
  filtersUsers: [],
  filtersVehicles: []
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.FILTER_GET_COUPONS_SUCCESS:
      return {
        ...state,
        filtersCoupons: actions.filters,
      };

    case types.FILTER_GET_USERS_SUCCESS:
      return {
        ...state,
        filtersUsers: actions.filters,
      };

      case types.FILTER_GET_VEHICLE_SUCCESS:
        return {
          ...state,
          filtersVehicles: actions.filters,
        };
      
    default:
      return state;
  }
}
