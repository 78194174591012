import * as types from "../../constants";

const initialState = {
  coupons: [],
  total: 0,
  skip: 0,
  limit: 25,
  page: 0,
  load: false,
  filter: false,
  textSearch: "",
  filters: []
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {

    case types.COUPON_GET_REQUEST:
      return {
        ...state,
        load: true
      };

    case types.COUPONS_GET_SUCCESS:
      return {
        ...state,
        coupons: actions.coupons,
        total: actions.total,
        skip: actions.skip,
        limit: actions.limit,
        page: actions.page,
        load: false,
        filter: actions.filter,
        textSearch: actions.textSearch,
        filters: actions.filters
      };




    default:
      return state;
  }
}
