import * as types from "../../constants";

const initialState = {
  payment: null,
  payments: [],
  total: 0,
  skip: 0,
  limit: 25,
  page: 0,
  load: false
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {

    case types.PAYMENT_GET_REQUEST:
      return {
        ...state,
        load: true
      };

    case types.PAYMENTS_GET_SUCCESS:
      return {
        ...state,
        payments: actions.payments,
        total: actions.total,
        skip: actions.skip,
        limit: actions.limit,
        page: actions.page,
        load: false
      };

    case types.PAYMENT_GET_SUCCESS:
      return {
        ...state,
        payment: actions.payment,
        load: false
      };


    default:
      return state;
  }
}
