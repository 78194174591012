import * as types from "../../constants";

const initialState = {
  userStatistic: {},
  vehicleStatistic: {},
  couponStatistic: {},
  paymentStatistic: {},
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.STATISTIC_GET_USER:
      return {
        ...state,
        userStatistic: actions.userStatistic,
      };

    case types.STATISTIC_GET_VEHICLE:
      return {
        ...state,
        vehicleStatistic: actions.vehicleStatistic,
      };


    case types.STATISTIC_GET_COUPON:
      return {
        ...state,
        couponStatistic: actions.couponStatistic,
      };

    case types.STATISTIC_GET_PAYMENT:
      return {
        ...state,
        paymentStatistic: actions.paymentStatistic,
      };

    default:
      return state;
  }
}
