import * as types from "../../constants";
import {
    searchUserService,
    searchCouponService,
    searchVehicleService,
    searchCouponExportService,
    searchUserExportService,
    searchVehicleExportService
} from "../../services/searchService";

export function searchUserTable(skip = 0, limit = 5, text, callback, page = 0, filters = []) {
    return async (dispatch) => {
        dispatch({ type: types.SEARCH_GET_REQUEST });

        return searchUserService(skip, limit, text, filters)
            .then((response) => {
                dispatch({
                    type: types.USERS_GET_SUCCESS,
                    users: response.users,
                    total: response.total,
                    skip: skip,
                    limit: limit,
                    page: page,
                    filter: true,
                    textSearch: text,
                    filters: filters
                });
                callback();
            })
            .catch((error) => {
                dispatch({ type: types.SEARCH_GET_ERROR });
                throw error;
            });
    };
}

export function searchUserExport(text, filters, callback) {
    return async (dispatch) => {
        dispatch({ type: types.SEARCH_GET_REQUEST });

        return searchUserExportService(text, filters)
            .then((response) => {
                if (response.total > 0) {
                    dispatch({
                        type: types.USER_EXPORT_SUCCESS
                    });

                    callback(response.results)
                }
            })
            .catch((error) => {
                dispatch({ type: types.SEARCH_GET_ERROR });
                throw error;
            });
    };
}


export function searchCouponTable(skip = 0, limit = 5, text, callback, page = 0, filters = []) {
    return async (dispatch) => {
        dispatch({ type: types.SEARCH_GET_REQUEST });

        return searchCouponService(skip, limit, text, filters)
            .then((response) => {
                if (response.total > 0) {
                    dispatch({
                        type: types.COUPONS_GET_SUCCESS,
                        coupons: response.results,
                        total: response.total,
                        skip: skip,
                        limit: limit,
                        page: page,
                        filter: true,
                        textSearch: text,
                        filters: filters
                    });
                }

                callback(response.total);
            })
            .catch((error) => {
                dispatch({ type: types.SEARCH_GET_ERROR });
                throw error;
            });
    };
}


export function searchCouponExport(text, filters, callback) {
    return async (dispatch) => {
        dispatch({ type: types.SEARCH_GET_REQUEST });

        return searchCouponExportService(text, filters)
            .then((response) => {
                if (response.total > 0) {
                    dispatch({
                        type: types.COUPONS_EXPORT_SUCCESS
                    });

                    callback(response.results)
                }
            })
            .catch((error) => {
                dispatch({ type: types.SEARCH_GET_ERROR });
                throw error;
            });
    };
}

export function searchVehicleTable(skip = 0, limit = 5, text, callback, page = 0, filters = []) {
    return async (dispatch) => {
        dispatch({ type: types.SEARCH_GET_REQUEST });

        return searchVehicleService(skip, limit, text, filters)
            .then((response) => {
                dispatch({
                    type: types.VEHICLES_GET_SUCCESS,
                    vehicles: response.vehicles,
                    total: response.total,
                    skip: skip,
                    limit: limit,
                    page: page,
                    filter: true,
                    textSearch: text,
                    filters: filters
                });
                callback();
            })
            .catch((error) => {
                dispatch({ type: types.SEARCH_GET_ERROR });
                throw error;
            });
    };
}

export function searchVehicleExport(text, filters, callback) {
    return async (dispatch) => {
        dispatch({ type: types.SEARCH_GET_REQUEST });

        return searchVehicleExportService(text, filters)
            .then((response) => {
                if (response.total > 0) {
                    dispatch({
                        type: types.USER_EXPORT_SUCCESS
                    });

                    callback(response.results)
                }
            })
            .catch((error) => {
                dispatch({ type: types.SEARCH_GET_ERROR });
                throw error;
            });
    };
}