import * as types from "../../constants";

const initialState = {
  user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
};


export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.AUTH_SIGN_IN_SUCCESS:

      var user = {
        id: actions.id,
        email: actions.email,
        name: actions.name,
        lastname: actions.lastname,
        access: actions.access,
        token: actions.token
      }

      localStorage.setItem('user', JSON.stringify(user));

      return {
        ...state,
        user: user
      };

    case types.AUTH_SIGN_OUT:

      localStorage.setItem('user', null);

      return {
        ...state,
        user: undefined,
      };

    default:
      return state;
  }
}
