export const OPTIONS_VERIFICATION = [
    { value: "Exento", text: "Exento" },
    { value: "00", text: "00" },
    { value: "0", text: "0" },
    { value: "1", text: "1" },
    { value: "2", text: "2" }
]

export const OPTIONS_RENEWAL = [
    { value: "true", text: "SI" },
    { value: "false", text: "NO" }
]