import * as types from "../../constants";

const initialState = {
    loading: false,
    results: [],
    total: -1
}

export default function reducer(state = initialState, actions) {
    switch (actions.type) {

        case types.SEARCH_GET_REQUEST:
            return {
                ...state,
                loading: true,
                results: [],
                total: -1
            };

        case types.SEARCH_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                results: actions.results,
                total: actions.total
            };

        default:
            return state;
    }
}