import React from "react";
import styled from "styled-components/macro";
import { Power } from "react-feather";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Badge,
  Avatar,
  Typography as MuiTypography,
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
} from "@material-ui/core";


import { spacing } from "@material-ui/system";

const Typography = styled(MuiTypography)(spacing);
import { signOut } from "../redux/actions/authActions";

const SidebarFooterBadge = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)}px;
  span {
    background-color: ${(props) =>
    props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;

const GridName = styled(Grid)`
  display: flex;
  align-items: center;
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;


function UserHeader_Alert() {

  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const auth = useSelector((state) => state.authReducer);

  const history = useHistory();
  const dispatch = useDispatch();

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };


  const handleSignOut = async () => {
    await dispatch(signOut());
    history.push("/auth/sign-in");
  };

  return (
    <React.Fragment>

      <Grid container spacing={2}>
        <GridName item>

          <Typography variant="body2" >
            {auth?.user?.name + " " + auth?.user?.lastname}
          </Typography>

        </GridName>
        <Grid item>

          <Tooltip title="Account">


            <Avatar
              alt="Lucy Lavender"
              src="/static/img/avatars/unavatar.png"
              onClick={toggleMenu}
            />
          </Tooltip>
          <Menu
            id="menu-appbar"
            anchorEl={anchorMenu}
            open={Boolean(anchorMenu)}
            onClose={closeMenu}
          >
            <MenuItem onClick={handleSignOut}>Cerrar sesión</MenuItem>
          </Menu>

        </Grid>
      </Grid>

    </React.Fragment>
  );
}

export default UserHeader_Alert;
