import * as types from "../../constants";

const initialState = {
  vehicle: null,
  vehiclesResume: [],
  vehicles: [],
  total: 0,
  skip: 0,
  limit: 25,
  page: 0,
  load: false,
  filterids: localStorage.getItem('filterids') ? JSON.parse(localStorage.getItem('filterids')) : [],
  name: localStorage.getItem('name') ? JSON.parse(localStorage.getItem('name')) : [],
  type: localStorage.getItem('type') ? JSON.parse(localStorage.getItem('type')) : "",
  filter: false,
  textSearch: "",
  filters: []
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {

    case types.VEHICLE_GET_REQUEST:
      return {
        ...state,
        load: true
      };

    case types.VEHICLES_GET_SUCCESS:
      return {
        ...state,
        vehicles: actions.vehicles,
        total: actions.total,
        skip: actions.skip,
        limit: actions.limit,
        page: actions.page,
        load: false,
        filter: actions.filter,
        textSearch: actions.textSearch,
        filters: actions.filters
      };

    case types.VEHICLES_RESUME_GET_SUCCESS:
      return {
        ...state,
        vehicles: actions.vehicles,
        load: false
      };

    case types.VEHICLES_FILTER_IDS:
      localStorage.setItem('filterids', JSON.stringify(actions.filterids));
      localStorage.setItem('type', JSON.stringify({ type: "ids" }));
      return {
        ...state,
        type: "ids",
        filterids: actions.filterids
      };

    case types.VEHICLES_FILTER_STATES:
      localStorage.setItem('name', JSON.stringify(actions.name));
      localStorage.setItem('type', JSON.stringify({ type: "name" }));
      return {
        ...state,
        type: "name",
        name: actions.name
      };

    case types.VEHICLE_GET_SUCCESS:
      return {
        ...state,
        vehicle: actions.vehicle,
        load: false
      };


    default:
      return state;
  }
}
