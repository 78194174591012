import * as types from "../../constants";

const initialState = {
  message: '',
  error: false
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.CONTINGENCIA_SEND_PUSH:
      return {
        ...state,
        error: false,
        message: 'Enviando...'
      };
    case types.CONTINGENCIA_SEND_PUSH_SUCCESS:
      return {
        ...state,
        error: false,
        message: actions.payload
      };

    case types.CONTINGENCIA_SEND_PUSH_ERROR:
      return {
        ...state,
        error: true,
        message: 'Error.'
      };

    case types.CONTINGENCIA_SEND_PUSH_PURGE:
      return {
        ...initialState
      };

    default:
      return state;
  }
}