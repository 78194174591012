import React, { useEffect, useState, useRef } from 'react';
import styled, { withTheme } from "styled-components/macro";

import {
    InputBase,
    Button as MuiButton,
    Snackbar,
    IconButton
} from "@material-ui/core";

import { useHistory } from "react-router-dom";

import { Close as CloseIcon, Cancel as CancelIcon } from "@material-ui/icons";

const Search = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 2px;    
  position: relative;
  width: 400px;
  background-color: #5D5D77;
  padding: 2px 5px;
  
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;
  > input {
    color: #FFFFFF;
    padding-top: ${(props) => props.theme.spacing(2.5)}px;
    padding-right: ${(props) => props.theme.spacing(2.5)}px;
    padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
    padding-left: ${(props) => props.theme.spacing(6)}px;
    width: 250px;
    background-color: #5D5D77;
    font-weight: 500;
  }
`;

const Button = styled(MuiButton)`
  background-color: #818198;
  height: 30px;
`;

const WrapResult = styled.div`
    position: absolute;
    top: 100%;
    left: 0;
    -webkit-transform-origin: center top;
    transform-origin: center top;
    white-space: normal;
    text-align: left;
    text-transform: none;
    background: #fff;
    margin-top: 0.5em;
    width: 31em;
    border-radius: 0.28571429rem;
    -webkit-box-shadow: 0 2px 4px 0 rgb(34 36 38 / 12%), 0 2px 10px 0 rgb(34 36 38 / 15%);
    box-shadow: 0 2px 4px 0 rgb(34 36 38 / 12%), 0 2px 10px 0 rgb(34 36 38 / 15%);
    border: 1px solid #d4d4d5;
    z-index: 998;  
`;

const Result = styled.div`
    cursor:${(props) => props.activo && "pointer"};
    display: block;
    overflow: hidden;
    font-size: 1em;
    padding: 0.85714286em 1.14285714em;
    color: rgba(0,0,0,.87);
    line-height: 1.33;
    border-bottom: 1px solid rgba(34,36,38,.1);
    z-index: 1998;  
`;

const ResultMore = styled(Result)`
    color: rgba(65,105,225,.87);
`;

const AutoComplete = ({
    placeholder = "Buscar ...",
    onClickSearch = () => { },
    onRedirect = () => { },
    results = [],
    total = -1 }) => {

    const [show, setShow] = useState(false);
    const [value, setValue] = useState("");

    const history = useHistory();

    const [alert, setAlert] = useState({
        open: false,
        vertical: "top",
        horizontal: "center",
        message: ""
    });

    const { vertical, horizontal, open, message } = alert;


    const onChangeInput = (event) => {
        setValue(event.target.value);
    }

    const onPressSearch = () => {

        if (value) {
            onClickSearch(value, (resultTotal) => {
                setValue("");
                if(resultTotal ==0){
                    handleClick({ vertical: "top", horizontal: "center", message: "No se han encontrado resultados para tú búsqueda" })
                }                
                //setShow(true)
            });
        } else {
            handleClick({ vertical: "top", horizontal: "center", message: "Ingrese un texto para buscar" })
        }
    }

    const selectValue = (item) => {
        setShow(false)

        if (item.id != -1)
            setValue(item.description)
    }

    const redirect = async (row) => {
        //return history.push("results/")
        onRedirect(value);
    }


    const renderResult = () => {

        if (!results.length || !show)
            return null;

        return (
            <WrapResult>
                {results.map((item, index) => (
                    <Result key={index} onClick={() => selectValue(item)} activo={(item.id != -1)}>
                        {item.description}
                    </Result>
                ))}
                {
                    (total > 1) &&
                    <ResultMore onClick={() => redirect()} activo={true}>
                        {"Mostrar mas resultados ..."}
                    </ResultMore>
                }
            </WrapResult>
        )

    }

    const lostFocus = () => {
        setTimeout(() => {
            setShow(false)
        }, 100);
    }


    const handleClick = (newState) => {
        setAlert({ open: true, ...newState });
    };

    const handleClose = () => {
        setAlert({ ...alert, open: false });
    };

    return (
        <>
            <Search >
                <Input
                    value={value}
                    placeholder={placeholder}
                    onChange={onChangeInput}
                    onFocus={(e) => { setShow(true) }}
                    onBlur={(e) => { lostFocus() }}
                />

                <Button
                    variant="contained"
                    aria-haspopup="true"
                    onClick={onPressSearch}
                    onBlur={(e) => { lostFocus() }}
                >
                    Buscar
                </Button>

                {renderResult()}

            </Search>

            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                onClose={handleClose}
                message={message}
                key={vertical + horizontal}
                action={
                    <React.Fragment>
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </React.Fragment>
                }
            />
        </>
    )

}


export default withTheme(AutoComplete);