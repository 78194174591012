import { PATH_IMAGE } from "./Configuration";

export const LOCATION = PATH_IMAGE + "/static/img/icon/location.svg";
export const PRINT = PATH_IMAGE + "/static/img/icon/print.svg";
export const ARROW_RIGHT_GREEN = PATH_IMAGE + "/static/img/icon/arrow_right_green.svg";
export const GARAGE = PATH_IMAGE + "/static/img/icon/garage.svg";
export const CARD = PATH_IMAGE + "/static/img/icon/card.svg";
export const VERIFICATION = PATH_IMAGE + "/static/img/icon/verification.svg";

export const NOT_CIRCULATE = PATH_IMAGE + "/static/img/icon/how_icons/not_circulate.svg";
export const NOT_CIRCULATE_MOTO = PATH_IMAGE + "/static/img/icon/how_icons/not_circulate_moto.svg";
export const CHECKUP = PATH_IMAGE + "/static/img/icon/how_icons/checkup.svg";
export const CHECKUP_MOTO = PATH_IMAGE + "/static/img/icon/how_icons/checkup_moto.svg";
export const TENURE = PATH_IMAGE + "/static/img/icon/how_icons/tenure.svg";
export const TENURE_MOTO = PATH_IMAGE + "/static/img/icon/how_icons/tenure_moto.svg";
export const CIRCULATION_CARD = PATH_IMAGE + "/static/img/icon/how_icons/circulation_card.svg";
export const MAINTENANCE = PATH_IMAGE + "/static/img/icon/how_icons/maintenance.svg";
export const MAINTENANCE_MOTO = PATH_IMAGE + "/static/img/icon/how_icons/maintenance_moto.svg";
export const STATE = PATH_IMAGE + "/static/img/icon/state.svg";
export const SIGNIN_SCREEN_1 = PATH_IMAGE + "/static/img/login/screen_1.png";
export const SIGNIN_SCREEN_2 = PATH_IMAGE + "/static/img/login/screen_2.png";
export const SIGNIN_SCREEN_3 = PATH_IMAGE + "/static/img/login/screen_3.png";
export const SIGNIN_ALERTA = PATH_IMAGE + "/static/img/login/logo_alerta_miauto.png";
export const CALLCENTER_ALERTA = PATH_IMAGE + "/static/img/login/call-center.svg";
export const CLOUD_UPLOAD = PATH_IMAGE + "/static/img/icon/cloud_upload.png";
export const ICON_CAR = PATH_IMAGE + "/static/img/icon/car.png";
export const FOTOCIVICA = PATH_IMAGE + "/static/img/unsplash/fotocivica.png";
export const CAR = PATH_IMAGE + "/static/img/icon/car.png";
export const MOTORCYCLE = PATH_IMAGE + "/static/img/icon/motorcycle.png";

export const ICON_CAR_Y = PATH_IMAGE + "/static/img/icon/car_yellow.png";
export const ICON_CAR_R = PATH_IMAGE + "/static/img/icon/car_red.png";
export const ICON_CAR_P = PATH_IMAGE + "/static/img/icon/car_pink.png";
export const ICON_CAR_G = PATH_IMAGE + "/static/img/icon/car_green.png";
export const ICON_CAR_B = PATH_IMAGE + "/static/img/icon/car_blue.png";