import * as types from "../../constants";

const initialState = {
  admins: [],
  admin: {},
  total: 0,
  admin: {},
  skip: 0,
  limit: 5,
  page: 0
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.USER_GET_SUCCESS:
      return {
        ...state,
        admin: actions.admin,
      };
    case types.ADMINS_GET_SUCCESS:
      return {
        ...state,
        admins: actions.admins,
        total: actions.total,
        skip: actions.skip,
        limit: actions.limit,
        page: actions.page
      };

    case types.ADMIN_GET_SUCCESS:
      return {
        ...state,
        admin: actions.admin,
      };

    case types.CLEAR_ADMIN:
      return {
        ...state,
        admin: {},
      };

    default:
      return state;
  }
}
