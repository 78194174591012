import * as types from "../../constants";

const initialState = {
  state: null,
  statesActives: [],
  statesInactives: []
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.STATES_GET_SUCCESS:
      return {
        ...state,
        statesActives: actions.states ? actions.states.filter(state => state.active == true) : [],
        statesInactives: actions.states ? actions.states.filter(state => state.active == false) : [],
      };

    case types.STATE_GET_SUCCESS:
      return {
        ...state,
        state: actions.state
      };

    default:
      return state;
  }
}
