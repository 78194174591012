import axios from "axios";
import { URL_API } from "../utils/Configuration";
import { getBarerToken } from "../utils/token";

export function searchUserService(skip, limit, text, filters) {
    return new Promise((resolve, reject) => {
        axios.post(`${URL_API}/v1/search/users?skip=${skip}&limit=${limit}`,
            {
                textSearch: text,
                filters: filters
            },
            getBarerToken())
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data.data);
                }

                reject(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function searchUserExportService(text, filters) {
    return new Promise((resolve, reject) => {
        axios.post(`${URL_API}/v1/search/users/export`,
            {
                textSearch: text,
                filters: filters
            },
            getBarerToken())
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data.data);
                }

                reject(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function searchCouponService(skip, limit, text, filters) {
    return new Promise((resolve, reject) => {
        axios.post(`${URL_API}/v1/search/coupons?skip=${skip}&limit=${limit}`,
            {
                textSearch: text,
                filters: filters
            },
            getBarerToken())
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data.data);
                }

                reject(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function searchCouponExportService(text, filters) {
    return new Promise((resolve, reject) => {
        axios.post(`${URL_API}/v1/search/coupons/export`,
            {
                textSearch: text,
                filters: filters
            },
            getBarerToken())
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data.data);
                }

                reject(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function searchVehicleService(skip, limit, text, filters) {
    return new Promise((resolve, reject) => {
        axios.post(`${URL_API}/v1/search/vehicles?skip=${skip}&limit=${limit}`,
            {
                textSearch: text,
                filters: filters
            },
            getBarerToken())
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data.data);
                }

                reject(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function searchVehicleExportService(text, filters) {
    return new Promise((resolve, reject) => {
        axios.post(`${URL_API}/v1/search/vehicles/export`,
            {
                textSearch: text,
                filters: filters
            },
            getBarerToken())
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data.data);
                }

                reject(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}
