import axios from "axios";
import { URL_API } from "../utils/Configuration";

export function signIn(credentials) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${URL_API}/v1/admin/login`, credentials)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        //reject(response.data);
      })
      .catch((error) => {
        reject({ message: "Correo o contraseña incorrecta" });
        //reject(error);
      });
  });
}

export function signUp(credentials) {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/auth/sign-up", credentials)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function resetPassword(credentials) {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/auth/reset-password", credentials)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
