import * as types from "../../constants";

const initialState = {
  user: { vehicles: [] },
  users: [],
  total: 0,
  skip: 0,
  limit: 25,
  page: 0,
  load: false,
  filter: false,
  textSearch: "",
  filters: []
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {

    case types.USER_GET_REQUEST:
      return {
        ...state,
        load: true
      };

    case types.USER_GET_SUCCESS:
      return {
        ...state,
        user: actions.user,
        load: false
      };

    case types.USERS_GET_SUCCESS:
      return {
        ...state,
        users: actions.users,
        total: actions.total,
        skip: actions.skip,
        limit: actions.limit,
        page: actions.page,
        load: false,
        filter: actions.filter,
        textSearch: actions.textSearch,
        filters: actions.filters
      };

    case types.CLEAR_USER:
      return {
        ...state,
        user: { vehicles: [] }
      };


    default:
      return state;
  }
}
