import * as types from "../../constants";

const initialState = {
  events: [],
  event: {},
  total: 0,
  skip: 0,
  limit: 25,
  page: 0,
  load: false
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {

    case types.EVENT_GET_REQUEST:
      return {
        ...state,
        load: true
      };

    case types.EVENTS_GET_SUCCESS:
      return {
        ...state,
        events: actions.events,
        total: actions.total,
        skip: actions.skip,
        limit: actions.limit,
        page: actions.page,
        load: false
      };

    case types.EVENT_GET_SUCCESS:
      var event = actions.event;
      event.dia_mes = actions.event.cron.dia_mes;
      event.dia_semana = actions.event.cron.dia_semana;
      event.hora = actions.event.cron.hora;
      event.mes = actions.event.cron.mes;
      event.minuto = actions.event.cron.minuto;
      return {
        ...state,
        event: event,
      };

    case types.EVENT_CLEAR:
      return {
        ...state,
        event: {},
      };
    default:
      return state;
  }
}
