import * as types from "../../constants";

const initialState = {
    text: "",
    show: false,
    key: null
}

export default function reducer(state = initialState, actions) {
    switch (actions.type) {
        case types.HEADER_TEXT:
            return {
                ...state,
                text: actions.text,
                show: actions.show,
                key: actions.key

            };

        default:
            return state;
    }
}