import * as types from "../../constants";

const initialState = {
  extraRulers: [],
  total: 0,
  skip: 0,
  limit: 25,
  page: 0,
  load: false,
  filter: false,
  textSearch: "",
  filters: []
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.DOUBLEALERT_GET_SUCCESS:
      return {
        ...state,
        extraRulers: actions.extraRulers,
      };

    default:
      return state;
  }
}
