import React, { useState } from "react";
import styled, { withTheme } from "styled-components/macro";
import { darken } from "polished";
import { useDispatch, useSelector } from "react-redux";
import AutoComplete from "./AutoComplete";
import { searchUserTable, searchCouponTable, searchVehicleTable } from "../redux/actions/searchActions";

import {
  Grid,
  Hidden,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
  Menu,
  MenuItem
} from "@material-ui/core";

import { Menu as MenuIcon } from "@material-ui/icons";

import UserHeader_Alert from "./UserHeader_Alert";


const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background}; // custom back
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;


const AppBarComponent = ({ onDrawerToggle }) => {

  const dispatch = useDispatch();
  const [anchorMenu, setAnchorMenu] = useState(null);
  const headerReduce = useSelector((state) => state.headerReduce);
  const searchReducer = useSelector((state) => state.searchReducer);
  const couponReducer = useSelector((state) => state.couponReducer);
  const userReducer = useSelector((state) => state.userReducer);

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleSignOut = async () => {
    await dispatch(signOut());
    history.push("/auth/sign-in");
  };


  const onClickSearch = async (textSearch, callback) => {

    switch (headerReduce.key) {
      case "users":
        await dispatch(searchUserTable(userReducer.skip, userReducer.limit, textSearch, callback));
        break;

      case "coupon":
        await dispatch(searchCouponTable(couponReducer.skip, couponReducer.limit, textSearch, callback));
        break;

      case "vehicle":
        await dispatch(searchVehicleTable(couponReducer.skip, couponReducer.limit, textSearch, callback));
        break;

      default:
        break;
    }

  }

  const onRedirect = async (textSearch) => {

    switch (headerReduce.key) {
      case "users":
        await dispatch(searchUserTable(userReducer.skip, userReducer.limit, textSearch, callback));
        break;

      case "coupon":
        await dispatch(searchCouponTable(couponReducer.skip, couponReducer.limit, textSearch, callback));
        break;

      case "vehicle":
        await dispatch(searchVehicleTable(couponReducer.skip, couponReducer.limit, textSearch, callback));
        break;

      default:
        break;
    }

  }

  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center">
            <Hidden mdUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item>
              {headerReduce.show &&
                <AutoComplete
                  placeholder={headerReduce.text}
                  onClickSearch={(text, callback) => onClickSearch(text, callback)}
                  onRedirect={(textSearch) => onRedirect(textSearch)}
                  results={searchReducer.results}
                  total={searchReducer.total} />
              }
              <Menu
                anchorEl={anchorMenu}
                open={Boolean(anchorMenu)}
                onClose={closeMenu}
              >
                <MenuItem onClick={handleSignOut}>Cerrar sesión</MenuItem>
              </Menu>
            </Grid>
            <Grid item xs />
            <Grid item>
              <UserHeader_Alert />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  )
};

export default withTheme(AppBarComponent);
